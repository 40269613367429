<script>
import ActivityService from "@/api/services/activity.service";
import AccessLevel from "@/shared/enums/aceessLevels";
import LiveCleaningStatus from "@/shared/enums/liveCleaningStatus";

export default {
  props: {
    project: Object,
    loggedInUser: Object
  },
  data() {
    return {
      accessLevelEnum: AccessLevel,
      liveCleaningStatus: LiveCleaningStatus
    };
  },
  methods: {
    clickViewResults(project) {
      this.trackActivity("VIEW");
      this.$router.push({
        path: "datafile-result",
        query: { datafileId: project.id }
      });
    },
    clickViewInstructions(project) {
      this.$router.push({
        path: "snippet-integration-instructions",
        query: { datafileId: project.id }
      });
    },
    async trackActivity(action) {
      await ActivityService.createActivity({
        owner: this.loggedInUser.id,
        companyId: this.loggedInUser.company.id,
        action
      });
    },
    clickDeleteDatafile(project) {
      if (this.loggedInUser.accessLevel === this.accessLevelEnum.SUPER_ADMIN) {
        this.$emit("DeleteDetafile", project);
      } else {
        this.$emit("RemoveDetafile", project);
      }
    },
    clickChangeDatafileStatus(project) {
      this.$emit("ChangeDetafileStatus", project);
    },
    clickDatafileOwnershipChange(project) {
      this.$emit("ChangeDetafileOwnership", project);
    },
    clickResetDatafile(project) {
      this.$emit("ResetDatafile", project);
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <!-- Project Name, Date & Category -->
        <div class="card-title col-md-5">
          <div
            class="text-limit mb-2"
            style="cursor: pointer"
            @click="
              project.isLiveCleaning !== liveCleaningStatus.draft
                ? clickViewResults(project)
                : clickViewInstructions(project)
            "
          >
            <i
              v-if="
                project.isLiveCleaning &&
                project.isLiveCleaning === liveCleaningStatus.running
              "
              class="bx bxs-circle bx-flashing text-success mr-1"
            ></i
            >{{ project.name }}
          </div>
          <!-- Project Cataegory -->
          <b-badge style="font-size: 12px" variant="light" class="mr-2">
            <i
              :class="{
                'bx bx-code-alt': `${project.importType}` === 'LIVE',
                'bx bx-file': `${project.importType}` === 'XLXS'
              }"
            ></i>
            <span> {{ project.importType }} Import </span>
          </b-badge>
          <!-- Date -->
          <small>
            {{ project.createdDate.slice(0, 10) }}
          </small>
        </div>
        <!-- Certificate Level & Deleted Status -->
        <div class="col-md-2">
          <div v-if="project.certificateLevel" class="mb-2">
            <b-badge
              style="font-size: 12px"
              variant="gold"
              pill
            >
              {{ project.certificateLevel }}
            </b-badge>
          </div>
          <div v-if="project.isDeleted && this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN">
            <b-badge
              style="font-size: 12px"
              variant="danger"
            >
              Deleted
            </b-badge>
          </div>
        </div>
        <!-- Owners & Company -->
        <div class="col-md-2 text-center">
          <!-- Owner -->
          <span
            v-b-tooltip.hover.left.v-light
            :title="project.owner.name.first + ' ' + project.owner.name.last"
          >
            <img
              v-if="project.owner.image"
              :src="project.owner.image"
              class=""
              height="20"
              style="border-radius: 50%"
            />
            <img
              v-else
              src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
              class=""
              height="20"
              style="border-radius: 50%"
            />
          </span>
          <!-- Company -->
          <span
            class="ml-2"
            v-b-tooltip.hover.left.v-light
            :title="project.company.name"
          >
            <img
              v-if="project.company.image"
              :src="project.company.image"
              class=""
              height="20"
            />
            <img
              v-else
              src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
              class=""
              height="20"
              style="border-radius: 50%"
            />
          </span>
        </div>
        <!-- Actions -->
        <div class="col-md-3 text-right">
          <!-- View Result Page Button -->
          <b-button
            v-if="project.isLiveCleaning !== liveCleaningStatus.draft"
            @click="clickViewResults(project)"
            variant="success"
            class="btn-sm"
            >{{ $t("datafiles.viewMoreButton") }}</b-button
          >
          <!-- View Instructions button -->
          <b-button
            v-if="project.isLiveCleaning === liveCleaningStatus.draft"
            @click="clickViewInstructions(project)"
            variant="primary"
            class="btn-sm"
            >{{ $t("datafiles.viewInstructionsButton") }}</b-button
          >
          <!-- Close/Stop Datafile -->
          <b-button
            v-if="
              project.isLiveCleaning &&
              project.isLiveCleaning === liveCleaningStatus.running
            "
            @click="clickChangeDatafileStatus(project)"
            variant="danger"
            class="btn-sm ml-2"
            ><i class="bx bx-stop"></i
          ></b-button>
          <!-- Other Actions -->
          <b-dropdown
            class="ml-2"
            size="sm"
            variant="light"
            dropright
            v-if="
              this.loggedInUser.accessLevel !== accessLevelEnum.EMPLOYEE ||
              (this.loggedInUser.accessLevel === accessLevelEnum.EMPLOYEE &&
                project.isLiveCleaning)
            "
          >
            <template v-slot:button-content>
              <i class="bx bx-dots-vertical-rounded"></i>
            </template>
            <!-- Re-Open Datafile -->
            <b-dropdown-item
              @click="clickChangeDatafileStatus(project)"
              v-if="
                project.isLiveCleaning &&
                project.isLiveCleaning === liveCleaningStatus.stop
              "
            >
              <i class="bx bx-sync mr-1"></i>
              {{ $t("datafiles.reopen") }}
            </b-dropdown-item>
            <!-- Reset Datafile -->
            <b-dropdown-item
              @click="clickResetDatafile(project)"
              v-if="
                this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN &&
                (project.isLiveCleaning === liveCleaningStatus.stop ||
                  !project.isLiveCleaning)
              "
              ><i class="bx bx-reset mr-1"></i>
              {{ $t("datafiles.reupload") }}
            </b-dropdown-item>
            <!-- Change Datafile Ownership -->
            <b-dropdown-item
              @click="clickDatafileOwnershipChange(project)"
              v-if="
                this.loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN
              "
            >
              <i class="bx bx-transfer-alt mr-1"></i>
              {{ $t("datafiles.changeOwnership") }}
            </b-dropdown-item>
            <!-- Delete Datafile -->
            <b-dropdown-item
              v-if="this.loggedInUser.accessLevel !== accessLevelEnum.EMPLOYEE"
              @click="clickDeleteDatafile(project)"
            >
              <i class="bx bx-trash mr-1"></i>
              {{ $t("datafiles.delete") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
