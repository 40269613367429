import ResponseStatus from "../../shared/enums/responseStatus";
import { ApiCall } from "../apiBase";

class ActivityService {
  /************* get All Activities for Compnay*********/
  async getAllCompanyActivitiess(companyId) {
    let activities = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyActivities($companyId: ID!){
            getCompanyActivities(companyId: $companyId) {
              id,
              owner {
                email
              }
              action
              createdDate
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (result?.data?.data?.getCompanyActivities && !result?.data?.errors) {
      activities = result.data.data.getCompanyActivities;
    }

    return activities;
  }

  /******** create activity ******/
  async createActivity(activity) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation createActivity($input: ActivityInput!) {
            createActivity(activityInput: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: activity
        }
      }
    });

    if (result?.data?.data?.createActivity && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new ActivityService();
