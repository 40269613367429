<script>
import DatafileResultService from "../../../../api/services/datafileResult.service";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileObject: Object
  },
  data() {
    return {
      datafile: null,
      datafileResetSuccess: 0,
      datafileResetFailed: 0,
      isLoading: false,
      dismissSecs: 3,
      dismissCountDown: null,
      updateScores: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    this.datafile = this.datafileObject;
  },
  methods: {
    async resetDatafile() {
      try {
        const response = await DatafileResultService.reuploadDatafile(
          this.datafile.id,
          this.updateScores
        );

        if (response) {
          this.isLoading = false;
          this.datafileResetSuccess = this.dismissSecs;
          this.$emit("reload");
        }
      } catch (error) {
        console.log(error.message);
        this.datafileResetFailed = this.dismissSecs;
        this.isLoading = false;
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    clickCloseButton() {
      this.$emit("reload");
    },
    async clickResetDataset() {
      this.isLoading = true;
      await this.resetDatafile();
    }
  }
};
</script>

<template>
  <!-- Reset Dataset Model -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="mt-3 mb-3 text-center" v-if="!isLoading">
      <b-alert
        :show="datafileResetSuccess"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="col-md-12"
        variant="success"
        dismissible
        >{{ $t("datafiles.resetDatafileModal.successMsg") }}</b-alert
      >
      <b-alert
        :show="datafileResetFailed"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="col-md-12"
        variant="danger"
        dismissible
        >{{ $t("datafiles.resetDatafileModal.errorMsg") }}</b-alert
      >
      <div class="col-md-12 mb-3">
        <img
          src="@/assets/images/red-warning.png"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 80px"
        />
        <h5>{{ $t("datafiles.resetDatafileModal.title") }}</h5>
      </div>
      <div class="col-md-12 mb-4">
        <p>
          <strong>{{ datafile.name }}</strong>
        </p>
      </div>
      <div class="col-md-12">
        <p>
          {{ $t("datafiles.resetDatafileModal.text1") }}
          <br />
          {{ $t("datafiles.resetDatafileModal.text2") }}
        </p>
      </div>
      <div class="col-md-12 mb-5">
        <b-form-checkbox v-model="updateScores" name="check-button" switch>
          {{ $t("datafiles.resetDatafileModal.overwriteCheckbox") }}
        </b-form-checkbox>
      </div>
      <div class="col-md-12 mt-3">
        <b-button variant="warning" @click="clickResetDataset">{{
          $t("datafiles.resetDatafileModal.resetButton")
        }}</b-button>
        <b-button variant="light" @click="clickCloseButton" class="ml-2">{{
          $t("datafiles.resetDatafileModal.cancelButton")
        }}</b-button>
      </div>
    </div>

    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
